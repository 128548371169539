import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { apolloClient } from '~gro-plugins'
import get from 'get-value'

export default {
  getSettings () {
    const { result, loading, refetch } = useQuery(gql`
      query getAlertSettings {
        alert{
          alertSetting{
            all{
              uuid
              name
              type
              isEnabled
              devices{
                serialNumber
                group{
                  uuid
                  name
                },
              },
              deviceGroups{
                uuid
                name
              },
              ranges{
                uuid
                sensorType
                lowerThreshold
                upperThreshold
              },
              maxChangeSettings{
                uuid
                sensorType
                maxDrop
                maxRise
                timeWindow
              }
            }
          }
        }
      }`)
    return {
      loading,
      refetch,
      settings: useResult(result, [], data => data.alert.alertSetting.all),
    }
  },
  async getRecentAsync (page = 0) {
    const result = await apolloClient.query({
      query: gql`
        query getRecentAlertsAsync($page: Int!){
          alert{
            recent(first: 20, page: $page) {
              data {
                uuid
                name
                receivedAt
                isRead
                content
                device{
                  group{
                    uuid
                  }
                }
              }
            }
          }
        }
      `,
      variables: { page },
      fetchPolicy: 'no-cache',
    })
    return get(result, 'data.alert.recent.data', { default: [] })
  },
}
