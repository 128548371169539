import moment from 'moment'
import authApi from './api'
import authQueries from './queries'
import authMutations from './mutations'
import RevokedCredentialsException from './Exceptions/RevokedCredentialsException'
import { Storage } from '~gro-plugins'

export default class User {
  constructor (serialNumber) {
    this.bridgeSerial = serialNumber
    this._tokensFetched = false
    this.loadTokens()
  }

  async loadTokens () {
    if (this._tokensFetched) return
    const tokens = await Storage.getFrom(
      'bridges',
      this.bridgeSerial,
      {
        accessToken: null,
        refreshToken: null,
        accessTokenExpires: null,
      },
    )
    this._updateTokenData(tokens)
  }

  async getSetting (key, fallback = null, force = false) {
    if (!this._tokensFetched) {
      await this.loadTokens()
    }
    if (!this.canAccess()) {
      return fallback
    }
    if (force || !await Storage.has(`setting.${key}`)) {
      await this.reloadSettings()

      if (!await Storage.has(`setting.${key}`) || await Storage.get(`setting.${key}`) === null) {
        await Storage.set(`setting.${key}`, fallback)
        return fallback
      }
    }
    return Storage.get(`setting.${key}`)
  }

  async reloadSettings () {
    try {
      const settings = await authQueries.getSettingsAsync()
      if (settings) return this.storeSettings(settings)
    } catch (e) {
      // ..
    }
  }

  async storeSettings (settings) {
    for (const setting of Object.keys(settings).filter(setting => setting !== '__typename')) {
      if (setting !== 'axis') {
        await Storage.set(`setting.${setting}`, settings[setting])
      } else {
        for (const axisSetting of Object.values(settings.axis)) {
          await Storage.set(`setting.axis.${axisSetting.sensorType}`, {
            max: axisSetting.max,
            min: axisSetting.min,
            autoScale: axisSetting.autoScale,
          })
        }
      }
    }
  }

  canAccess () {
    return !!this.accessToken &&
      !!this._accessTokenExpires &&
      moment(this._accessTokenExpires).isAfter(moment())
  }

  canRefresh () {
    return !!this._refreshToken
  }

  async login (password = undefined, uuid = undefined) {
    const response = await authApi.login(password, uuid)
    if (!response) {
      return false
    }
    this._updateTokenData(response)
    await authMutations.invalidateUserPassword(uuid)
    await this.persist()
    return true
  }

  async refreshTokens () {
    try {
      const response = await authApi.refreshToken(this._refreshToken)
      this._updateTokenData(response)
      await this.persist()
    } catch (e) {
      if (e instanceof RevokedCredentialsException) {
        console.error('Refresh token was revoked. No way to proceed with authentication.', e)
        this.revokeTokens()
      }
      throw e
    }
  }

  async revokeTokens () {
    this.accessToken = null
    this._refreshToken = null
    this._accessTokenExpires = null
    await this.persist()
  }

  async persist () {
    return Storage.updateIn('bridges', {
      serialNumber: this.bridgeSerial,
      accessToken: this.accessToken,
      refreshToken: this._refreshToken,
      accessTokenExpires: this._accessTokenExpires,
    })
  }

  _updateTokenData (response) {
    if (response.accessToken) {
      this.accessToken = response.accessToken
    }
    if (response.refreshToken) {
      this._refreshToken = response.refreshToken
    }
    if (response.accessTokenExpiresIn) {
      this._accessTokenExpires = moment().add(response.accessTokenExpiresIn * 0.90, 'seconds')
    }
    if (response.accessTokenExpires) {
      this._accessTokenExpires = moment(response.accessTokenExpires)
    }
    this._tokensFetched = true
  }
}
