import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { apolloClient, AppStatus } from '~gro-plugins'
import get from 'get-value'
import { scannerStatus } from '~gro-modules/Arp'
import { computed } from 'vue'

export default {
  getConvertors (pollInterval = 10 * 1000, paused = false) {
    const { result, loading, refetch } = useQuery(gql`
      query {
        convertor {
          all {
            _id: hardwareAddress
            name
            hardwareAddress
            ipAddress
            createdAt
            lastSeen
            group {
              name
            }
          }
        }
      }`, {}, {
      pollInterval,
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
    })
    return {
      loading,
      refetch,
      convertors: useResult(result, [], data => data.convertor.all),
    }
  },
  getConvertor (hardwareAddress, pollInterval = null) {
    const { result, loading, onResult, refetch } = useQuery(gql`
      query($hardwareAddress: String!) {
        convertor {
          get(hardwareAddress: $hardwareAddress) {
            _id: hardwareAddress
            name
            hardwareAddress
            ipAddress
            createdAt
            installedAt
            lastSeen
            updateIntervalMinutes
            group {
              uuid
              name
            }
          }
        }
      }`, {
      hardwareAddress,
    }, {
      pollInterval,
    })

    return {
      loading,
      onResult,
      refetch,
      convertor: useResult(result, [], data => data.convertor.get),
    }
  },
  getVirtualConverters (pollInterval = 10 * 1000, paused = false) {
    const { result, loading, refetch } = useQuery(gql`
      query {
        virtualConverter {
          all {
            _id: uuid
            name
            uuid
            lastSeen
            deviceGroup {
              name
            }
          }
        }
      }`, {}, {
      pollInterval,
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
    })
    return {
      loading,
      refetch,
      virtualConverters: useResult(result, [], data => data.virtualConverter.all),
    }
  },
  getVirtualConverter (uuid, pollInterval = null) {
    const { result, loading, onResult, refetch } = useQuery(gql`
      query($uuid: ID!) {
        virtualConverter {
          get(uuid: $uuid) {
            _id: uuid
            name
            lastSeen
            deviceGroup {
              uuid
              name
            }
          }
        }
      }`, {
      uuid,
    }, {
      pollInterval,
    })

    return {
      loading,
      onResult,
      refetch,
      virtualConverter: useResult(result, [], data => data.virtualConverter.get),
    }
  },
  async getScanStatus (fresh = true, hardwareAddress = null) {
    const result = await apolloClient.query({
      query: gql`query ($hardwareAddress: String) {
        convertor {
          scanStatus(hardwareAddress: $hardwareAddress) {
            status
            results {
              hardwareAddress
              ipAddress
            }
          }
        }
      }`,
      variables: { hardwareAddress },
      fetchPolicy: fresh ? 'no-cache' : 'cache-first',
    })
    return {
      status: get(result, 'data.convertor.scanStatus.status', { default: scannerStatus.IDLE }),
      candidates: get(result, 'data.convertor.scanStatus.results', { default: [] }),
    }
  },

}
