import { CLIMATE_ZONE, ROOT_ZONE } from '~gro-modules/Sensor'

export const GROSENS = 'GROSENS'
export const ELSYS_CO2 = 'ELSYS_CO2'
export const PAR = 'PAR'
export const ELSYS_ELT_2 = 'ELSYS_ELT_2'
export const ELSYS_ERS_LITE = 'ELSYS_ERS_LITE'
export const TEROS_12 = 'TEROS_12'
export const CP_CO2C = 'CP_CO2C'
export const CP_CO2 = 'CP_CO2'
export const CP_TH = 'CP_TH'
export const SMARTBOX_ASSOCIATE = 'SMARTBOX_ASSOCIATE'
export const AME_ROOTZONE = 'AME_ROOTZONE'
export const AME_AMBIENT = 'AME_AMBIENT'
export const AME_AMBIENT_CO2 = 'AME_AMBIENT_CO2'

export const getZoneForType = (type) => {
  if ([
    GROSENS,
    TEROS_12,
    SMARTBOX_ASSOCIATE,
    AME_ROOTZONE,
  ].includes(type)) {
    return ROOT_ZONE
  }
  return CLIMATE_ZONE
}
