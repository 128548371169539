import { ROOT_ZONE, CLIMATE_ZONE } from '~gro-modules/Sensor'
import { ROOT, HUMIDITY_TEMP, HUMIDITY_TEMP_CO2 } from './typeGroups'
import {
  AME_AMBIENT, AME_AMBIENT_CO2,
  AME_ROOTZONE,
  CP_CO2C,
  CP_CO2,
  CP_TH,
  ELSYS_CO2,
  ELSYS_ELT_2, ELSYS_ERS_LITE,
  GROSENS,
  PAR,
  SMARTBOX_ASSOCIATE,
  TEROS_12,
} from './types'

const SENSOR_ZONE_MAP = {
  [ROOT_ZONE]: [
    GROSENS,
    TEROS_12,
    SMARTBOX_ASSOCIATE,
    AME_ROOTZONE,
  ],
  [CLIMATE_ZONE]: [
    ELSYS_CO2,
    PAR,
    ELSYS_ELT_2,
    ELSYS_ERS_LITE,
    CP_CO2C,
    CP_CO2,
    CP_TH,
    AME_AMBIENT,
    AME_AMBIENT_CO2,
  ],
}

const SENSOR_TYPE_GROUP_MAP = {
  [ROOT]: [
    GROSENS,
    TEROS_12,
    SMARTBOX_ASSOCIATE,
    AME_ROOTZONE,
  ],
  [HUMIDITY_TEMP]: [
    PAR,
    ELSYS_ELT_2,
    ELSYS_ERS_LITE,
    CP_TH,
    AME_AMBIENT,
  ],
  [HUMIDITY_TEMP_CO2]: [
    ELSYS_CO2,
    CP_CO2,
    CP_CO2C,
    AME_AMBIENT_CO2,
  ],
}

export const deviceSensorZone = (deviceType) => {
  return Object.keys(SENSOR_ZONE_MAP).find(zone => SENSOR_ZONE_MAP[zone].includes(deviceType))
}

export const deviceSensorTypeGroup = (deviceType) => {
  return Object.keys(SENSOR_TYPE_GROUP_MAP).find(group => SENSOR_TYPE_GROUP_MAP[group].includes(deviceType))
}

export const deviceZoneEquals = (deviceType, zone) => {
  return SENSOR_ZONE_MAP[zone] && SENSOR_ZONE_MAP[zone].includes(deviceType)
}
