import { ROLE_OWNER, ROLE_MANAGER, is } from '~gro-modules/Auth/roles'
import { BridgeRepository } from '~gro-modules/Bridge'

export default {
  invite (user) {
    const bridge = BridgeRepository.current
    if (!bridge) return false

    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },

  manage (user) {
    const bridge = BridgeRepository.current
    if (!bridge) return false

    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },

  assignNonViewerRole (user) {
    return is(user, ROLE_OWNER)
  },
}
