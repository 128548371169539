import gql from 'graphql-tag'
import { useQuery, useResult } from '@vue/apollo-composable'
import { apolloClient } from '~gro-plugins'
import get from 'get-value'

export default {
  async getStatuses () {
    const result = await apolloClient.query({
      query: gql`
        query {
          export{
            all {
              status
              uuid
            }
          }
        }`,
      fetchPolicy: 'no-cache',
    })
    return get(result, 'data.export.all', { default: [] })
  },

  getAll () {
    const { result, refetch } = useQuery(
      gql`
        query {
          export{
            all {
              status
              name
              uuid
              removeFileAt
              createdAt
            }
          }
        }`, {},
      {
        fetchPolicy: 'no-cache',
      },
    )
    return {
      refetch,
      exports: useResult(result, [], data => data.export.all),
    }
  },
}
