import { VEG, MJ } from '~gro-modules/Bridge/growerTypes'

export const NONE = 'NONE'
export const PROPAGATION = 'PROPAGATION'
export const VEGETATIVE = 'VEGETATIVE'
export const FLOWERING = 'FLOWERING'
export const DRYING = 'DRYING'
export const MOTHER = 'MOTHER'
export const IRRIGATION = 'IRRIGATION'
export const CLIMATE = 'CLIMATE'

export default {
  [MJ]: [
    NONE,
    PROPAGATION,
    VEGETATIVE,
    FLOWERING,
    DRYING,
    MOTHER,
  ],
  [VEG]: [
    IRRIGATION,
    CLIMATE,
  ],
}
