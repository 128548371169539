import { ROLE_MANAGER, ROLE_OWNER, is } from '~gro-modules/Auth/roles'

export default {
  connectEgro (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  manageGateways (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  manageCloudServices (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  manageSettings (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  manageConvertors (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  manageSmartboxes (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  factoryReset (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  startSystemUpdate (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  exportData (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
}
