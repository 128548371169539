import ipValidator from 'ip-validator'
import axios from 'axios'
import bridgeType from './bridgeType'

const RECENT_ERROR_TIMEOUT_SECONDS = 60
const MAX_CONSECUTIVE_ISSUES = 2

export default class Host {
  constructor (url, connectionType, sanitize = true) {
    this.port = null
    this.reachable = true
    this.recentErrors = 0
    this.recentErrorTimeout = null
    this.reachable = true
    this.connectionType = connectionType

    if (sanitize) {
      if (url.indexOf('[') !== 0 && ipValidator.ipv6(url)) {
        url = `[${url}]`
      }
      if (url.indexOf('http') !== 0) {
        url = `http://${url}`
      }
    }
    this.url = url.replace(/\/$/, '')
  }

  withPort (port) {
    this.port = port
    return this
  }

  resolve (uri = '') {
    if (!this.port) return `${this.url}/${uri}`
    return `${this.url}:${this.port}/${uri}`
  }

  is (host) {
    return this.url === host.url
  }

  reset () {
    this.reachable = true
    this.recentErrors = 0
    this._unsetRecentErrorTimeout()
  }

  registerConnectionIssue () {
    if (!this.reachable) {
      return
    }

    this.recentErrors++
    if (this.recentErrors > MAX_CONSECUTIVE_ISSUES) {
      this._markUnreachable()
      throw Error('Too many connection issues')
    }
    this._setRecentErrorTimeout()
  }

  async testConnection () {
    if (!this.reachable) return
    try {
      if (await this.readBeacon()) {
        return true
      }
    } catch (e) {
      console.log(e)
      // ..
    }
    this._markUnreachable()
    return false
  }

  async readBeacon (timeout = 5000) {
    const response = await axios.get(this.resolve('api/bridge/beacon'), {
      timeout,
      headers: {
        accept: 'application/json',
      },
    })
    if (response.status === 403 || response.status === 403) {
      throw Error('Host is not a bridge')
    }

    if (response.status !== 200) {
      return null
    }
    if ([
      'grosens virtual bridge',
      'grosens bridge',
    ].includes(response.data.device.toLowerCase()) === false || !response.data.serialNumber) {
      throw Error('Host is not a bridge')
    }

    let type = bridgeType.ONSITE
    if (response.data.device.toLowerCase() === 'grosens virtual bridge') {
      type = bridgeType.VIRTUAL
    }

    return {
      serialNumber: response.data.serialNumber,
      name: response.data.facilityName,
      connection: this.connectionType,
      region: response.data.region,
      cloudRegion: response.data.cloudRegion,
      canRecover: !!response.data.canRecover,
      isProvisioning: !!response.data.isProvisioning,
      isClaimed: response.data.isClaimed,
      isConfigured: !!response.data.configured,
      cloudUrl: response.data.cloudEndpoint,
      vpnUrl: response.data.vpnEndpoint,
      ipAddresses: response.data.ipAddresses,
      type,
    }
  }

  toString () {
    return this.url
  }

  _markUnreachable () {
    this.reachable = false
  }

  _setRecentErrorTimeout () {
    this._unsetRecentErrorTimeout()

    this.recentErrorTimeout = setTimeout(() => {
      this.recentErrors = 0
      this.recentErrorTimeout = null
    }, RECENT_ERROR_TIMEOUT_SECONDS * 1000)
  }

  _unsetRecentErrorTimeout () {
    if (this.recentErrorTimeout) {
      clearTimeout(this.recentErrorTimeout)
    }
  }
}
