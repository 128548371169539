import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async createDeviceGroup (deviceGroup) {
    return apolloClient.mutate({
      mutation: gql`mutation create($group: DeviceGroupInput!) {
        deviceCreateDeviceGroup(group: $group)
      }`,
      variables: {
        group: {
          name: deviceGroup.name,
          description: deviceGroup.description,
          type: deviceGroup.type,
          slabType: deviceGroup.slabType,
          growingMedium: deviceGroup.growingMedium,
        },
      },
    })
  },
  async updateDeviceGroup (deviceGroup) {
    return apolloClient.mutate({
      mutation: gql`mutation update($group: DeviceGroupInput!) {
        deviceUpdateDeviceGroup(group: $group)
      }`,
      variables: {
        group: {
          uuid: deviceGroup.uuid,
          name: deviceGroup.name,
          description: deviceGroup.description,
          type: deviceGroup.type,
          slabType: deviceGroup.slabType,
          growingMedium: deviceGroup.growingMedium,
        },
      },
    })
  },
  async updateDeviceGroups (groups) {
    return apolloClient.mutate({
      mutation: gql`mutation update($groups: [DeviceGroupInput!]!) {
        deviceUpdateDeviceGroups(groups: $groups)
      }`,
      variables: { groups },
    })
  },
  async deleteDeviceGroup (uuid) {
    return apolloClient.mutate({
      mutation: gql`mutation update($uuid: ID!) {
        deviceDeleteDeviceGroup(uuid: $uuid)
      }`,
      variables: {
        uuid,
      },
    })
  },
}
