import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async startScan () {
    return apolloClient.mutate({
      mutation: gql`mutation {
        convertorScan
      }`,
    })
  },
  async installConvertor (convertor) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation create($convertor: ConvertorInstallInput!) {
        convertorInstall(convertor: $convertor)
      }`,
      variables: {
        convertor: {
          name: convertor.name,
          ipAddress: convertor.ipAddress,
          hardwareAddress: convertor.hardwareAddress,
          groupUuid: convertor.groupUuid,
        },
      },
    })

    return !result.errors
  },
  async updateConvertor (convertor) {
    return apolloClient.mutate({
      mutation: gql`mutation update($convertor: ConvertorUpdateInput!) {
        convertorUpdate(convertor: $convertor)
      }`,
      variables: {
        convertor: {
          name: convertor.name,
          hardwareAddress: convertor.hardwareAddress,
          updateIntervalMinutes: convertor.updateIntervalMinutes,
          groupUuid: convertor.groupUuid,
        },
      },
    })
  },
  async deleteConvertor (hardwareAddress) {
    return apolloClient.mutate({
      mutation: gql`mutation update($hardwareAddress: String!) {
        convertorDelete(hardwareAddress: $hardwareAddress)
      }`,
      variables: {
        hardwareAddress,
      },
    })
  },

  async updateVirtualConverter (converter) {
    return apolloClient.mutate({
      mutation: gql`mutation update($converter: VirtualConverterUpdateInput!) {
        converterUpdateVirtualConverter(virtualConverter: $converter)
      }`,
      variables: {
        converter: {
          uuid: converter.uuid,
          name: converter.name,
          deviceGroupUuid: converter.deviceGroupUuid,
        },
      },
    })
  },
  async deleteVirtualConverter (uuid) {
    return apolloClient.mutate({
      mutation: gql`mutation update($uuid: ID!) {
        converterDeleteVirtualConverter(uuid: $uuid)
      }`,
      variables: {
        uuid,
      },
    })
  },
}
