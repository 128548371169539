import { MiddlewareHelper } from '~gro-helpers'
import BridgeRepository from './BridgeRepository'

export class BridgeMiddleware {
  constructor (failedRoute = { path: '/' }) {
    this._failedRoute = failedRoute
    MiddlewareHelper.add('selectedBridge', this.checkBridgeConnected.bind(this))
  }

  async checkBridgeConnected () {
    if (await BridgeRepository.getCurrent()) return null
    return this._failedRoute
  }
}
