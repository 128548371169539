import { ROLE_OWNER, ROLE_MANAGER, is } from '~gro-modules/Auth/roles'

export default {
  add (user) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
  edit (user, deviceGroup) {
    return is(user, [ROLE_OWNER, ROLE_MANAGER])
  },
}
