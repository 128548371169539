import { Singleton } from '~gro-helpers'
import { bridgeQueries, bridgeMutations, BridgeRepository, bridgeApi, bridgeType } from '~gro-modules/Bridge'
import { reactive } from 'vue'

class BridgeUpdater extends Singleton {
  constructor () {
    super(BridgeUpdater, (instance) => {
      instance.timeout = null
      instance.status = reactive({
        updateProgress: 0,
        updateInstalling: false,
        versionsLoading: false,
        versionsFailed: false,
        latestVersion: null,
        currentVersion: null,
        updateAvailable: false,
        updateIsOptional: false,
        updateFailed: false,
        minApp: null,
        maxApp: null,
      })

      if (process.env.NODE_ENV === 'development') {
        window.$bridgeUpdater = instance
      }
    })
  }

  get updateAvailable () {
    return this.status.updateAvailable
  }

  get updateIsOptional () {
    return this.status.updateIsOptional
  }

  async loadStatus () {
    if (!await this.isUpdatableBridge()) return
    this.status.versionsLoading = true
    this.status.versionsFailed = false
    if (await BridgeRepository.bridgeHasAccess()) {
      return await this._loadStatusViaGraphQL()
    }
    return this._loadStatusViaApI()
  }

  async startBridgeUpdate () {
    if (!await this.isUpdatableBridge()) return
    let updateTriggered
    this.status.updateInstalling = true
    this.status.updateFailed = false

    if (await BridgeRepository.bridgeHasAccess()) {
      updateTriggered = await this._triggerUpdateOnBridge()
    } else {
      updateTriggered = await this._triggerUpdateViaAdmin()
    }

    if (!updateTriggered) {
      this.status.updateInstalling = false
      this.status.updateFailed = true
    }
  }

  async _loadStatusViaApI () {
    const result = await bridgeApi.getBridgeVersionInfo()
    if (!result) {
      this.status.versionsFailed = true
      this.status.versionsLoading = false
      return
    }
    this.status.versionsLoading = false
    this.status.updateIsOptional = false
    this.status.updateAvailable = result.update_available
    if (this.status.updateAvailable === false && this.status.updateInstalling === true) {
      this.status.updateInstalling = false
    }
    this.status.minApp = parseInt(result.min_app)
    this.status.maxApp = parseInt(result.max_app)
  }

  async _loadStatusViaGraphQL () {
    const result = await bridgeQueries.getBridgeVersionInfo()
    if (!result) {
      this.status.versionsFailed = true
      this.status.versionsLoading = false
      return
    }

    this.status.updateProgress = result.progress
    if (!result.current || !result.latest) {
      this.status.updateAvailable = null
      this.status.updateInstalling = false
    } else {
      this.status.latestVersion = {
        ...result.latest,
        buildNumber: parseInt(result.latest.buildNumber),
      }
      this.status.currentVersion = {
        ...result.current,
        buildNumber: parseInt(result.current.buildNumber),
      }
      this.status.updateAvailable = this.status.latestVersion.buildNumber > this.status.currentVersion.buildNumber
      if (this.status.updateAvailable === false && this.status.updateInstalling === true) {
        this.status.updateInstalling = false
      }
      this.status.minApp = parseInt(result.current.minApp)
      this.status.maxApp = parseInt(result.current.maxApp)
      this.status.updateIsOptional = !!result.latest.isSilent
    }
    this.status.versionsLoading = false
  }

  async _triggerUpdateViaAdmin () {
    return bridgeApi.updateToLatestVersion()
  }

  async _triggerUpdateOnBridge () {
    return bridgeMutations.updateToLatestVersion()
  }

  async isUpdatableBridge () {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) return false
    return bridge.type === bridgeType.ONSITE
  }
}

export default new BridgeUpdater()
