import { itemQuery } from '~gro-helpers'
import gql from 'graphql-tag'
import { apolloClient } from '~gro-plugins'
import { BridgeRepository } from '~gro-modules/Bridge'
import get from 'get-value'
import growerTypes from './growerTypes'

export default {
  getCurrent (...fields) {
    const returnObject = itemQuery('bridge.current', fields, null, 'serialNumber')
    if (fields.includes('facilityName')) {
      returnObject._meta.onResult(async result => {
        const facilityName = get(result, 'data.bridge.current.facilityName')
        if (!facilityName) return
        const bridge = await BridgeRepository.getCurrent()
        bridge.setName(facilityName)
      })
    }
    return returnObject
  },
  getSettings (...fields) {
    return itemQuery('bridge.settings', fields, null, null)
  },
  async getBridgeVersionInfo () {
    try {
      const result = await apolloClient.query({
        query: gql`
          query getBridgeVersionInfo {
            bridge {
              versionInfo {
                overallDownloadProgress
                current {
                  version
                  buildNumber
                  releaseNotes
                  releaseTrack
                  minApp
                  maxApp
                }
                latest {
                  version
                  buildNumber
                  releaseNotes
                  isSilent
                  releaseTrack
                  minApp
                  maxApp
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache',
        context: {
          dontDisconnect: true,
        },
      })
      if (!result.errors) {
        return {
          progress: get(result, 'data.bridge.versionInfo.overallDownloadProgress', { default: 0 }),
          current: get(result, 'data.bridge.versionInfo.current', { default: null }),
          latest: get(result, 'data.bridge.versionInfo.latest', { default: null }),
        }
      }
      if (result.errors.some(error => error.message?.startsWith('Cannot query field'))) {
        return this.getBridgeVersionInfoLegacy()
      }
    } catch (e) {
      console.error(e)
    }
    return false
  },
  async getBridgeVersionInfoLegacy () {
    try {
      const result = await apolloClient.query({
        query: gql`
          query getBridgeVersionInfoLegacy {
            bridge {
              versionInfo {
                overallDownloadProgress
                current {
                  version
                  buildNumber
                  releaseNotes
                  releaseTrack
                  minApp
                  maxApp
                }
                latest {
                  version
                  buildNumber
                  releaseNotes
                  releaseTrack
                  minApp
                  maxApp
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache',
        context: {
          dontDisconnect: true,
        },
      })
      if (!result.errors) {
        return {
          progress: get(result, 'data.bridge.versionInfo.overallDownloadProgress', { default: 0 }),
          current: get(result, 'data.bridge.versionInfo.current', { default: null }),
          latest: get(result, 'data.bridge.versionInfo.latest', { default: null }),
        }
      }
    } catch (e) {
      console.error(e)
    }
    return false
  },
  async getGrowerType () {
    const result = await apolloClient.query({
      query: gql`
        query getGrowerType {
          bridge{
            settings {
              growerType
            }
          }
        }`,
      fetchPolicy: 'no-cache',
    })
    return get(result, 'data.bridge.settings.growerType', { default: growerTypes[0] })
  },
  async checkRecoveryEmail () {
    const result = await apolloClient.query({
      query: gql`
        query checkRecoveryEmail {
          bridge{
            settings {
              recoveryEmail
            }
          }
        }`,
      fetchPolicy: 'no-cache',
    })
    return !!get(result, 'data.bridge.settings.recoveryEmail', { default: false })
  },

  async checkTimezone () {
    const result = await apolloClient.query({
      query: gql`
        query checkTimezone {
          bridge{
            settings {
              timezone
            }
          }
        }`,
      fetchPolicy: 'no-cache',
    })
    return !!get(result, 'data.bridge.settings.timezone', { default: false })
  },
}
