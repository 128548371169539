import { apolloClient, Storage } from '~gro-plugins'
import gql from 'graphql-tag'
import get from 'get-value'
import { Singleton } from '~gro-helpers'

class Settings extends Singleton {
  constructor () {
    super(Settings)
  }

  async get (key, fallback = null, force = false) {
    if (force) await this.refresh()

    if (!await Storage.has(`setting.${key}`)) {
      if (force) await Storage.set(`setting.${key}`, fallback)
      return fallback
    }
    return Storage.get(`setting.${key}`)
  }

  async refresh () {
    const result = await apolloClient.query({
      query: gql`query {
        auth{
          settings{
            temperatureUnit
            language
            axis{
              sensorType
              min
              max
              autoScale
            }
          }
        }
      }`,
      fetchPolicy: 'network-only',
    })
    const settings = get(result, 'data.auth.settings', { default: null })
    if (settings) {
      for (const setting of Object.keys(settings).filter(setting => setting !== '__typename')) {
        if (setting !== 'axis') {
          await Storage.set(`setting.${setting}`, settings[setting])
        } else {
          for (const axisSetting of Object.values(settings.axis)) {
            await Storage.set(`setting.axis.${axisSetting.sensorType}`, {
              max: axisSetting.max,
              min: axisSetting.min,
              autoScale: axisSetting.autoScale,
            })
          }
        }
      }
    }
  }

  async persist (input) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation update($input: SettingsInput!) {
        authUpdateSettings(settingsInput: $input)
      }`,
      variables: {
        input,
      },
    })
    if (result.errors) return result.errors
    await this.refresh()
  }
}

export default new Settings()
