export default [
  'NA_INDOOR_SLABS_75',
  'NA_INDOOR_SLABS_100',
  'NA_INDOOR_BLOCKS_HUGO_GR32',
  'NA_INDOOR_BLOCKS_HUGO_BR32',
  'NA_INDOOR_BLOCKS_UNI_GR40',
  'NA_INDOOR_BIG_BLOCKS',
  'NA_INDOOR_SMALL_BLOCKS_65',

  'GRODAN_ELITE',
  'GRODAN_GT_MASTER_100',
  'GRODAN_GT_MASTER_75',
  'GRODAN_GT_MASTER_DRY',
  'GRODAN_PRESTIGE_100',
  'GRODAN_PRESTIGE_85',
  'GRODAN_PRESTIGE_75',
  'GRODAN_GT_EXPERT_100',
  'GRODAN_GT_EXPERT_75',
  'GRODAN_SUPREME',
  'GRODAN_VITAL',
  'GRODAN_VITAL_DRY',
  'GRODAN_CLASSIC',
  'GRODAN_CLASSIC_MY_100',
  'GRODAN_CLASSIC_MY_75',
  'GRODAN_VITAFLOR_100',
  'GRODAN_VITAFLOR_75',
  'GRODAN_GRO_SUPREME_NG2',
  'FLORTOP_ROSA',
  'PROPAGATION_BLOCK',
  'OTHER_MINERAL_WOOL_100',
  'OTHER_MINERAL_WOOL_75',
  'SLAB_BY_DEFAULT',
  'GRODAN_OPTIONAL_1',
  'GRODAN_OPTIONAL_2',
]

export const fallbackSlabType = 'GENERIC'
