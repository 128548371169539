import { BridgeRepository } from '~gro-modules/Bridge'
import axios from 'axios'
import get from 'get-value'
import UnauthenticatedException from './Exceptions/UnauthenticatedException'
import RevokedCredentialsException from './Exceptions/RevokedCredentialsException'
import { v5 as uuidv5 } from 'uuid'

export default {
  async refreshToken (refreshToken) {
    return this.tokenRequest({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    })
  },
  async login (password = process.env.VUE_APP_OWNER_DEFAULT_PASSWORD, uuid = null) {
    const useGeneratedUuid = !uuid
    if (useGeneratedUuid) {
      uuid = await this.generateOwnerUuid()
    }

    try {
      return await this.tokenRequest({
        grant_type: 'password',
        username: uuid,
        password: `${password}`,
      })
    } catch (error) {
      if (error.message === 'Invalid authentication response for password' && useGeneratedUuid) {
        return this.login(password, process.env.VUE_APP_OWNER_USER_UUID)
      }
    }
    return null
  },
  async reinviteUser (uuid = null) {
    const useGeneratedUuid = !uuid
    if (useGeneratedUuid) {
      uuid = await this.generateOwnerUuid()
    }

    try {
      const bridge = await BridgeRepository.getCurrent()
      if (!bridge) return null

      const response = await axios.post(bridge.getHost().resolve(`api/auth/user/reinvite/${uuid}`), {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
      }, {
        timeout: 10 * 1000,
      })
      if (!response || response.status !== 200 || response.data?.length !== 0) {
        return false
      }
      return true
    } catch (error) {
      // ..
    }
    return null
  },

  async tokenRequest (request) {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) return null

    let response
    try {
      response = await axios.post(bridge.getTokenUrl(), {
        ...request,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
      }, {
        timeout: 10 * 1000,
      })
    } catch (error) {
      response = error.response
    }
    if (response?.status === 401) {
      if (get(response, 'data.message') === 'The refresh token is invalid.') {
        throw new RevokedCredentialsException()
      }
      throw new UnauthenticatedException()
    }

    if (!response || response.status !== 200) {
      if (!response) {
        bridge.registerConnectionIssue()
        if (bridge.failover()) {
          return this.tokenRequest(request)
        }
      }
      throw new Error('Invalid authentication response for ' + request.grant_type)
    }
    return {
      refreshToken: response.data.refresh_token,
      accessToken: response.data.access_token,
      accessTokenExpiresIn: response.data.expires_in,
    }
  },

  async requestOwnerRecovery () {
    try {
      const bridge = await BridgeRepository.getCurrent()
      await axios.post(bridge.getHost().resolve('api/auth/recover/owner'))
    } catch (e) {
      return false
    }
    return true
  },
  async registerRecoveryCode (code) {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) {
      return false
    }
    try {
      await axios.post(bridge.getHost().resolve('api/auth/recover/local-recovery'), {
        code,
      })
    } catch (e) {
      bridge.registerConnectionIssue()
      if (bridge.failover()) {
        return this.registerRecoveryCode(code)
      }
      return false
    }
    return true
  },
  async checkRecoveryCode (code) {
    try {
      const bridge = await BridgeRepository.getCurrent()
      const response = await axios.get(bridge.getHost().resolve(`api/auth/recover/local-recovery/${code}`))
      return { password: response.data.password }
    } catch (e) {
      return {
        error: e.response ? e.response.status : 408,
      }
    }
  },

  async validatePairCode (pairCode) {
    try {
      const bridge = await BridgeRepository.getCurrent()
      const response = await axios.get(bridge.getHost().resolve(`api/auth/paircode/getCredentials/${pairCode}`))
      return response.data
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async validateCloudPairCode (pairCode) {
    try {
      const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8400' : location.origin
      const response = await axios.get(`${baseUrl}/api/auth/paircode/getCredentials/${pairCode}`)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async generateOwnerUuid () {
    const bridge = await BridgeRepository.getCurrent()
    if (!process.env.VUE_APP_OWNER_USER_UUID_NAMESPACE || !process.env.VUE_APP_UUID_NAMESPACE) {
      console.error('Uuid namespaces not set in environment', process.env.VUE_APP_OWNER_USER_UUID_NAMESPACE, process.env.VUE_APP_UUID_NAMESPACE)
    }
    return uuidv5(process.env.VUE_APP_OWNER_USER_UUID_NAMESPACE + '/' + bridge.serialNumber, process.env.VUE_APP_UUID_NAMESPACE)
  },
}
