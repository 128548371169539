import { ref, computed } from 'vue'
import { Singleton } from '~gro-helpers'
import { Storage } from '~gro-plugins'

class DeviceGroupSorter extends Singleton {
  constructor () {
    super(DeviceGroupSorter, (instance) => {
      instance._mapping = ref(null)
    })
  }

  sort (groups) {
    return computed(() => {
      return [...groups.value].sort((groupA, groupB) => {
        const a = this._getMapping(groupA.uuid)
        const b = this._getMapping(groupB.uuid)
        return a - b
      })
    })
  }

  _getMapping (uuid) {
    if (this._mapping.value === null) {
      this._loadMapping()
      return 0
    }
    return this._mapping.value[uuid] !== undefined ? this._mapping.value[uuid] : 1000
  }

  async _loadMapping () {
    this._mapping.value = await Storage.get('device-group-order', [])
  }

  async storeMapping (groups) {
    this._mapping.value = groups.reduce((mapping, group, index) => {
      mapping[group.uuid] = index
      return mapping
    }, {})
    await Storage.set('device-group-order', this._mapping.value)
  }
}

export default new DeviceGroupSorter()
