import gql from 'graphql-tag'
import { apolloClient } from '~gro-plugins'

export default {
  async addDevice (device) {
    return apolloClient.mutate({
      mutation: gql`mutation ($device: DeviceInput!) {
        deviceAddDevice(device: $device)
      }`,
      variables: {
        device: {
          serialNumber: device.serialNumber,
          type: device.type,
          hardwareAddress: device.hardwareAddress,
          firmwareVersion: device.firmwareVersion,
          secret: device.secret,
          batteryPercentage: device.batteryPercentage,
        },
      },
    })
  },

  async assignToGroup (serialNumber, groupUuid) {
    return apolloClient.mutate({
      mutation: gql`mutation ($serialNumber: ID!, $groupUuid: ID) {
        deviceAssignToGroup(serialNumber: $serialNumber, groupUuid: $groupUuid)
      }`,
      variables: {
        serialNumber,
        groupUuid,
      },
    })
  },

  async setOptionalInfo (serialNumber, device) {
    return apolloClient.mutate({
      mutation: gql`mutation ($device: DeviceOptionalInput!) {
        deviceUpdateDeviceOptional(device: $device)
      }`,
      variables: {
        device: {
          serialNumber,
          name: device.name,
          description: device.description,
        },
      },
    })
  },

  async storeFirmwareVersion (serialNumber, firmwareVersion) {
    return apolloClient.mutate({
      mutation: gql`mutation ($serialNumber: ID!, $firmwareVersion: String!) {
        deviceUpdateFirmwareVersion(serialNumber: $serialNumber, firmwareVersion: $firmwareVersion)
      }`,
      variables: {
        serialNumber,
        firmwareVersion,
      },
    })
  },

  async deleteDevice (serialNumber) {
    return apolloClient.mutate({
      mutation: gql`mutation update($serialNumber: ID!) {
        deviceDeleteDevice(serialNumber: $serialNumber)
      }`,
      variables: {
        serialNumber,
      },
    })
  },
}
