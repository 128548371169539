import { useQuery, useResult } from '@vue/apollo-composable'
import { computed } from 'vue'
import gql from 'graphql-tag'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import { collectionQuery } from '~gro-helpers'
import { apolloClient, AppStatus } from '~gro-plugins'

export default {
  getAll (...fields) {
    return collectionQuery('groups', 'deviceGroup.all', fields)
  },
  getAllWithDevices () {
    const { result, loading, refetch } = useQuery(gql`
      query getAllGroupsWithDevices{
        deviceGroup {
          all {
            _id: uuid
            uuid
            name
            lightsOn
            lightsOff
            devices {
              _id: serialNumber
            }
          }
        }
      }
    `)

    return {
      loading, refetch, groups: useResult(result, [], data => data.deviceGroup.all),
    }
  },
  getWithDevices (uuid) {
    const { result, loading, onResult } = useQuery(gql`
      query getGroupWithDevices($uuid: ID!) {
        deviceGroup {
          get(uuid: $uuid) {
            _id: uuid
            uuid
            name
            description
            type
            slabType
            growingMedium
            lightsOn
            lightsOff
            devices {
              _id: serialNumber
              serialNumber
              type
              sensors {
                type
              }
            }
          }
        }
      }
    `, {
      uuid,
    })

    return {
      loading, onResult, group: useResult(result, {}, data => data.deviceGroup.get),
    }
  },
  getAllWithLatestMeasurements (paused = false, pollInterval = 10 * 1000) {
    const { result, loading, refetch } = useQuery(gql`
      query getGroupsWithLatestMeasurements($sensorTypes: [SensorType!]) {
        deviceGroup {
          all {
            _id: uuid
            uuid
            name
            description
            type
            lightsOn
            lightsOff
            slabType
            growingMedium
            devices {
              _id: serialNumber
              name
              lastSeen
              createdAt
              serialNumber
              type
              batteryPercentage
              sensors {
                id
                type
                measurements {
                  recent {
                    type
                    value
                  }
                }
              }
            }
            measurements {
              recent(types: $sensorTypes) {
                type
                value
                datetime
              }
            }
          }
        }
        device {
          unassignedToGroup {
            _id: serialNumber
          }
        }
      }`, {
      sensorTypes: SensorTypeHelper.renderableTypes,
    }, {
      pollInterval,
      fetchPolicy: 'cache-and-network',
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
    })

    return {
      loading,
      refetch,
      groups: useResult(result, [], data => data.deviceGroup.all),
      unassignedDeviceCount: useResult(result, 0, data => data.device.unassignedToGroup.length),
    }
  },
  getMeasurementWindowForGroups (variableRef, all = false) {
    const { result, loading } = useQuery(gql`
      query getMeasurementWindowForGroups(
        $sensorTypes: [SensorType!]
        $search: DeviceGroupSearch
        $interval: Int!
        $unit: IntervalUnit!
        $from: DateTime!
        $to: DateTime
      ) {
        deviceGroup {
          all (search: $search){
            _id: uuid
            uuid
            name
            measurements {
              window(
                from: $from
                to: $to
                types: $sensorTypes
                interval: $interval
                unit: $unit
              ) {
                type
                values
                timestamps
              }
            }
          }
        }
      }`, variableRef, {
      enabled: computed(() => {
        if (!variableRef.value.sensorTypes) {
          return false
        }
        if (!variableRef.value.sensorTypes.filter(type => !!type).length) {
          return false
        }
        if (all) {
          return true
        }
        return variableRef.value.search?.filter?.length
      }),
      context: {
        timeout: 30000,
      },
      fetchPolicy: 'no-cache',
    })
    return {
      loading,
      windows: useResult(result, [], data => data.deviceGroup.all.map(group => {
        return group.measurements.window.map(window => ({
          deviceGroup: { uuid: group.uuid }, ...window,
        }))
      })),
    }
  },
  async asyncDeviceGroupTypeCount (type) {
    const result = await apolloClient.query({
      query: gql`query asyncDeviceGroupTypeCount{
        deviceGroup {
          all {
            type
          }
        }
      }`,
      fetchPolicy: 'no-cache',
    })

    if (result.errors) {
      await this.$showGraphQLErrors(result.errors)
      return null
    }

    let count = 0

    Object.keys(result.data.deviceGroup.all).forEach((key) => {
      const item = result.data.deviceGroup.all[key]
      if (type === item.type) {
        count++
      }
    })

    return count
  },
}
