export const ROLE_OWNER = 'OWNER'
export const ROLE_MANAGER = 'MANAGER'
export const ROLE_VIEWER = 'VIEWER'

export const ROLES = {
  MANAGER: ROLE_MANAGER,
  VIEWER: ROLE_VIEWER,
}

export function is (user, roles) {
  if (!user) return false
  if (roles.some) {
    return roles.some((role) => user.role === role)
  }
  return user.role === roles
}
