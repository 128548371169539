import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async createSetting (input) {
    return await apolloClient.mutate({
      mutation: gql`mutation update($input: AlertSettingInput!) {
        alertCreateAlertSetting(setting: $input)
      }`,
      variables: { input },
    })
  },
  async updateSetting (input) {
    return await apolloClient.mutate({
      mutation: gql`mutation update($input: AlertSettingInput!) {
        alertUpdateAlertSetting(setting: $input)
      }`,
      variables: { input },
    })
  },
  async deleteSetting (uuid) {
    return await apolloClient.mutate({
      mutation: gql`mutation update($uuid: ID!) {
        alertDeleteAlertSetting(uuid: $uuid)
      }`,
      variables: { uuid },
    })
  },
  async markAsRead (uuids) {
    return apolloClient.mutate({
      mutation: gql`mutation update($uuids: [ID!]!) {
        alertMarkAsRead(uuids: $uuids)
      }`,
      variables: { uuids },
    })
  },
}
