import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { apolloClient } from '~gro-plugins'
import get from 'get-value'

export default {
  egroStatus: {
    isConnected: false,
  },

  async isConnected (refresh = false) {
    return !!(await this.getUser(refresh))
  },

  async getApiInfo (refresh = false) {
    const result = await apolloClient.query({
      query: gql`{
        egro {
          api {
            appUrl
            apiUrl
            authUrl
            authClientId
            authClientSecret
            authAudience
          }
        }
      }`,
      fetchPolicy: refresh ? 'network-only' : 'cache-first',
      context: {
        dontDisconnect: true,
      },
    })
    return get(result, 'data.egro.api', { default: null })
  },

  async getUser (refresh = false) {
    const result = await apolloClient.query({
      query: gql`{
        egro {
          user {
            id
            email
            locale
            companyId
            divisionId
          }
        }
      }`,
      fetchPolicy: refresh ? 'network-only' : 'cache-first',
      context: {
        dontDisconnect: true,
      },
    })

    const user = get(result, 'data.egro.user', { default: null })
    this.egroStatus.isConnected = !!user

    return user
  },

  getCompanies () {
    const { result, loading } = useQuery(gql`
      query {
        egro {
          companies {
            _id: id
            id
            name
          }
        }
      }
    `)

    return {
      loading,
      companies: useResult(result, [], data => data.egro.companies),
    }
  },

  getDivisions () {
    const { result, loading } = useQuery(gql`
      query {
        egro {
          divisions {
            _id: id
            id
            name
          }
        }
      }
    `)

    return {
      loading,
      divisions: useResult(result, [], data => data.egro.divisions),
    }
  },
}
