import * as Sentry from '@sentry/browser'
import { ref } from 'vue'
import { apolloClient, Gate, Storage } from '~gro-plugins'
import { Singleton } from '~gro-helpers'
import Bridge from './Bridge'

class BridgeRepository extends Singleton {
  constructor () {
    super(BridgeRepository, (instance) => {
      instance._bridges = ref([])
      instance._accessibleBridges = ref([])
      instance._current = ref(null)
      instance._connected = ref(false)
      instance._availableConnections = []

      if (process.env.NODE_ENV === 'development') {
        window.$bridges = instance
      }
    })
  }

  get current () {
    this.getCurrent()
    return this._current.value
  }

  get connected () {
    this.getConnected()
    return this._connected.value
  }

  get all () {
    this.getAll()
    return this._bridges.value
  }

  get accessibleBridges () {
    this.getAccessibleBridges()
    return this._accessibleBridges.value
  }

  async getAll () {
    if (!this._bridges.value.length && await Storage.has('bridges')) {
      (await Storage.get('bridges')).forEach(data => {
        if (!this._bridges.value.some(bridge => bridge.serialNumber === data.serialNumber)) {
          this._bridges.value.push(new Bridge(data))
        }
      })
    }
    return this._bridges.value
  }

  async getAccessibleBridges () {
    this._accessibleBridges.value = []
    await this.getAll()
    await Promise.all(this._bridges.value.map(async (bridge) => {
      if (!bridge.user) {
        return
      }

      await bridge.user.loadTokens()
      if (bridge.user.canRefresh() || bridge.user.canAccess()) {
        this._accessibleBridges.value.push(bridge)
      }
    }))
    return this._accessibleBridges.value
  }

  async add (properties) {
    let bridge = await this.find(properties.serialNumber)
    if (bridge) {
      bridge.update(properties)
    } else {
      bridge = new Bridge(properties)
      this._bridges.value.push(bridge)
    }
    await bridge.persist()
    return bridge
  }

  async bridgeIsSet () {
    if (await this.bridgeHasAccess()) return true
    await this.setCurrent(null)
    return false
  }

  async bridgeHasAccess () {
    const bridge = await this.getCurrent()
    if (!bridge) return false
    if (bridge.user && (bridge.user.canAccess() || bridge.user.canRefresh())) return true
    return false
  }

  async getCurrent () {
    if (!this._current.value && await Storage.has('current-bridge')) {
      const serialNumber = await Storage.get('current-bridge')
      const bridge = await this.find(serialNumber)
      if (!bridge) {
        this.setCurrent(null)
      }
      this._updateSentryContext()
      this._current.value = bridge
    }
    return this._current.value
  }

  async find (serialNumber) {
    return (await this.getAll()).find(bridge => bridge.serialNumber === serialNumber)
  }

  async getConnected () {
    const bridge = await this.getCurrent()
    if (!bridge) {
      this._connected.value = false
    } else {
      this._connected.value = bridge.connected
    }
    return this._connected
  }

  async setDisconnected () {
    if (await this.getCurrent()) {
      this.current.setDisconnected()
    }
  }

  async registerConnectionIssue () {
    if (await this.getCurrent()) {
      this.current.registerConnectionIssue()
    }
  }

  async setCurrent (bridge = null) {
    await this.setDisconnected()
    if (this.current && this.current.serialNumber !== bridge?.serialNumber) await apolloClient.cache.reset()
    if (bridge) {
      await Storage.set('current-bridge', bridge.serialNumber)
    } else {
      await Storage.forget('current-bridge')
    }
    this._current.value = bridge
    this._updateSentryContext()
    return this._current.value
  }

  async revokeCurrent () {
    if (!await this.getCurrent()) return
    this.current.setDisconnected()
    await this.current.user.revokeTokens()
  }

  async clearDisconnected () {
    const disconnectedBridges = (await this.getAll()).filter(bridge => !bridge.user.canAccess())
    await Promise.all(disconnectedBridges.map(async bridge => await Storage.removeFrom('bridges', bridge)))
  }

  async clear () {
    await Storage.forget('bridges')
    this._bridges.value = []
  }

  setAvailableConnections (connectionTypes = []) {
    this._availableConnections = connectionTypes
  }

  connectionIsEnabled (connectionType) {
    return this._availableConnections.includes(connectionType)
  }

  async _updateSentryContext () {
    try {
      const context = {
        bridge: null, user: null, storageDriver: Storage.storage.type,
      }
      if (this._current.value) {
        context.bridge = this._current.value.serialNumber
        if (await this.bridgeHasAccess()) {
          Gate.off('login', this._updateSentryContext.bind(this))
          await this._current.value.user?.loadTokens()
          const uuid = Gate._currentUser.value?.uuid

          context.user = {
            role: Gate._currentUser.value?.role,
            uuid: uuid ? `${uuid.substr(0, 3)}***${uuid.substr(-6)}` : null,
            canAccess: this._current.value.user?.canAccess(),
            canRefresh: this._current.value.user?.canRefresh(),
          }
        } else {
          Gate.on('login', this._updateSentryContext.bind(this))
        }
      }
      Sentry.setContext('bridge', context)
    } catch (e) {
      console.error('Unable to set Sentry context:', e)
    }
  }
}

export default new BridgeRepository()
