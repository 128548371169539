import Host from './Host'
import { Storage, apolloClient, Gate } from '~gro-plugins'
import { User } from '~gro-modules/Auth'
import { reactive } from 'vue'
import axios from 'axios'
import get from 'get-value'
import connectionType from './connectionType'
import { VERSION_OLD, VERSION_NEW } from './versions'
import BridgeRepository from './BridgeRepository'
import bridgeType from './bridgeType'
import * as Sentry from '@sentry/vue'

export default class Bridge {
  constructor (properties) {
    this._status = reactive({ connected: false })
    this.update(properties)
  }

  update (properties) {
    const {
      serialNumber = null,
      name = null,
      cloudUrl = null,
      vpnUrl = null,
      ipAddresses = [],
      region = false,
      cloudRegion = null,
      isVirtual = false,
      type = null,
      isConfigured = false,
      isClaimed = null,
      isProvisioning = false,
      canRecover = false,
      minApp = null,
      maxApp = null,
      updateAvailable = null,
      connection = null,
    } = properties
    this.serialNumber = serialNumber
    this.name = name || serialNumber
    this.region = region
    this.cloudRegion = cloudRegion
    this.cloudUrl = cloudUrl
    this.vpnUrl = vpnUrl
    this.ipAddresses = ipAddresses
    this.isConfigured = isConfigured
    this.isClaimed = isClaimed
    this.isProvisioning = isProvisioning
    this.canRecover = canRecover
    this.minApp = minApp
    this.maxApp = maxApp
    this.updateAvailable = updateAvailable
    this.user = this.user || new User(serialNumber)
    this.version = Bridge.getBridgeVersion(serialNumber)

    this.hosts = []
    this.currentHostIndex = 0
    this.type = type || (isVirtual ? bridgeType.VIRTUAL : bridgeType.ONSITE)

    this._prepareHosts()

    if (connection) {
      this.setConnectionType(connection)
    }
  }

  get connected () {
    return this._status.connected
  }

  static getBridgeVersion (serialNumber) {
    if (serialNumber.startsWith('BR')) return VERSION_OLD
    return VERSION_NEW
  }

  getHost (connectionType = null) {
    if (connectionType) {
      const host = this.hosts.find(host => host.connectionType === connectionType)
      if (!host) {
        return null
      }
      return host
    }
    return this.hosts[this.currentHostIndex]
  }

  getGraphQLUrl () {
    return this.getHost().resolve('graphql')
  }

  getTokenUrl () {
    return this.getHost().resolve('oauth/token')
  }

  getViewerUrl () {
    return this.getHost(connectionType.LOCAL).withPort(null).resolve('conditions')
  }

  async login (password = undefined, uuid = undefined) {
    const success = await this.user.login(password, uuid)
    if (success) Gate.emit('login')
    return success
  }

  async logout () {
    await this.user.revokeTokens()
    await apolloClient.resetStore()
    await Gate.resetUser()
    Gate.emit('logout')
    return this.persist()
  }

  async updateVersionInfo () {
    try {
      const response = await axios.get(this.getHost().resolve('api/bridge/version-info'), {
        timeout: 5 * 1000,
        headers: {
          accept: 'application/json',
        },
      })
      if (response.status === 404) {
        this.maxApp = 0
        this.updateAvailable = true
      }
      this.minApp = get(response, 'data.min_app', { default: null })
      this.maxApp = get(response, 'data.max_app', { default: null })
      this.updateAvailable = get(response, 'data.update_available', { default: null })
    } catch (e) {
      if (get(e, 'response.status', { default: null }) === 404) {
        this.maxApp = 0
        this.updateAvailable = true
      }
      console.log('version info error', e)
    }
    this.persist()
  }

  failover (forced = false) {
    const nextAvailableHostIndex = this.hosts.findIndex((host, index) => {
      if (index === this.currentHostIndex) return false
      return forced || host.reachable
    })

    if (nextAvailableHostIndex === -1) {
      return false
    }

    this.currentHostIndex = nextAvailableHostIndex
    return true
  }

  resetHosts () {
    this.hosts.forEach(host => host.reset())
  }

  async attemptConnection (connectionType) {
    if (this.getConnectionType() === connectionType) return
    const host = this.getHost(connectionType)
    if (host && await host.testConnection()) {
      this.setConnectionType(connectionType)
    }
  }

  setConnectionType (connectionType) {
    const hostIndex = this.hosts.findIndex(host => host.connectionType === connectionType)
    if (hostIndex === -1) {
      console.warn(`Setting connection to type [${connectionType}] but no host is available`)
      return
    }
    this.currentHostIndex = hostIndex
    Sentry.setContext('connection', { connection: connectionType })
    this.persist()
  }

  getConnectionType () {
    return this.getHost()?.connectionType
  }

  setConnected () {
    this._status.connected = true
  }

  registerConnectionIssue () {
    try {
      this.getHost().registerConnectionIssue()
    } catch (e) {
      if (e.message === 'Too many connection issues') {
        this.setDisconnected()
        return
      }
      throw e
    }
  }

  setDisconnected () {
    this._status.connected = false
  }

  setName (name) {
    this.name = name
    this.persist()
  }

  isOnsite () {
    return this.type === bridgeType.ONSITE
  }

  isVirtual () {
    return this.type === bridgeType.VIRTUAL
  }

  async refreshBeacon (connection = this.connection) {
    try {
      const host = this.getHost(connection)
      if (!host) return
      const beaconData = await host.readBeacon()
      if (beaconData) {
        this.update(beaconData)
      }
    } catch (e) {
      // ..
    }
  }

  async persist () {
    await Storage.updateIn('bridges', {
      serialNumber: this.serialNumber,
      name: this.name,
      region: this.region,
      cloudRegion: this.cloudRegion,
      isConfigured: this.isConfigured,
      isClaimed: this.isClaimed,
      cloudUrl: this.cloudUrl,
      vpnUrl: this.vpnUrl,
      ipAddresses: this.ipAddresses,
      connection: this.getConnectionType(),
      type: this.type,
    })
    return this.user.persist()
  }

  _prepareHosts () {
    if (this.type === bridgeType.ONSITE) {
      if (this.ipAddresses?.length && BridgeRepository.connectionIsEnabled(connectionType.LOCAL)) {
        this.hosts.push(new Host(this.ipAddresses[0], connectionType.LOCAL).withPort(8080))
      }

      if (BridgeRepository.connectionIsEnabled(connectionType.VPN)) {
        if (this.vpnUrl) {
          this.hosts.push(new Host(this.vpnUrl, connectionType.VPN))
        } else {
          const fallbackKey = `VUE_APP_VPN_FALLBACK_URL_${this.region}`
          if (process.env[fallbackKey]) {
            console.warn(`No vpn url set for bridge. Using fallback for ${this.region}`)
            this.hosts.push(new Host(`${process.env[fallbackKey]}/${this.serialNumber}/`, connectionType.VPN, false))
          } else {
            console.warn('No vpn url set for bridge. Using fallback for US')
            this.hosts.push(new Host(`${process.env.VUE_APP_VPN_FALLBACK_URL_US}/${this.serialNumber}/`, connectionType.VPN, false))
          }
        }
      }
    } else if (this.type === bridgeType.VIRTUAL) {
      if (BridgeRepository.connectionIsEnabled(connectionType.CLOUD)) {
        if (this.cloudUrl) {
          this.hosts.push(new Host(this.cloudUrl, connectionType.CLOUD))
        } else {
          const fallbackKey = `VUE_APP_CLOUD_FALLBACK_URL_${this.region}`
          if (process.env[fallbackKey]) {
            console.warn(`No cloud url set for bridge. Using fallback for ${this.region}`)
            this.hosts.push(new Host(`${process.env[fallbackKey]}/${this.serialNumber}/`, connectionType.CLOUD, false))
          } else {
            console.warn('No cloud url set for bridge. Using fallback for US')
            this.hosts.push(new Host(`${process.env.VUE_APP_CLOUD_FALLBACK_URL_US}/${this.serialNumber}/`, connectionType.CLOUD, false))
          }
        }
      }
    }
  }
}
