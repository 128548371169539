import { ref } from 'vue'
import { BridgeRepository } from './index'
import { Singleton } from '~gro-helpers'

const RESCAN_INTERVAL = 2000
const HOST_SCAN_TIMEOUT = 2000

class BridgeScanner extends Singleton {
  constructor () {
    super(BridgeScanner, (instance) => {
      instance.hosts = []
      instance.hostProviders = []
      instance.hostCallbacks = []
      instance.checkInterval = null
      instance.scanTimeout = null
      instance._isRunning = ref(false)
    })
  }

  get isRunning () {
    return this._isRunning.value
  }

  addHost (newHost) {
    if (!newHost || newHost.isEmpty || this.hosts.some(host => host.is(newHost))) {
      return
    }
    this.hosts.push(newHost)
  }

  addHostProvider (provider) {
    this.hostProviders.push(provider)
    if (this.isRunning) {
      provider.start(newHost => this.addHost(newHost))
    }
  }

  async scan (timeout = 30000) {
    if (this.isRunning) return
    this._isRunning.value = true
    await BridgeRepository.clearDisconnected()
    const bridges = await BridgeRepository.getAll()
    bridges.forEach(bridge => this.addHost(bridge.getHost()))
    this.hostProviders.forEach(provider => provider.start(newHost => this.addHost(newHost)))
    this.checkInterval = setInterval(() => this.isRunning ? this.scanAll() : null, RESCAN_INTERVAL)
    if (timeout) this.scanTimeout = setTimeout(() => this.stop(), timeout)
    this.scanAll()
  }

  async stop () {
    if (!this.isRunning) return
    this.hostProviders.forEach(provider => provider.stop())

    clearInterval(this.checkInterval)
    clearTimeout(this.scanTimeout)
    this.checkInterval = this.scanTimeout = null
    this._isRunning.value = false
  }

  removeHost (removedHost) {
    const index = this.hosts.findIndex(host => host.is(removedHost))
    if (index !== -1) {
      this.hosts.splice(index, 1)
    }
  }

  async scanAll () {
    await Promise.all(this.hosts.map(async (host) => this.check(host)))

    if (this.hosts.length === 0 &&
      this.hostProviders.every(provider => !provider.isRunning())
    ) {
      return this.stop()
    }
  }

  async check (host) {
    try {
      const bridgeData = await host.readBeacon(HOST_SCAN_TIMEOUT)
      if (!bridgeData) {
        return
      } // try again later
      await BridgeRepository.add(bridgeData)
    } catch (e) {
      // ..
    }
    this.removeHost(host)
  }
}

export default new BridgeScanner()
