import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import moment from 'moment'
import { AppStatus } from '~gro-plugins'
import { computed } from 'vue'

export default {
  get (serialNumber) {
    const { result, loading, onResult } = useQuery(gql`
      query getDevice($serialNumber: ID!) {
        device {
          get(serialNumber: $serialNumber) {
            _id: serialNumber
            serialNumber
            firmwareVersion
            name
            lastSeen
            createdAt
            description
            type
            group {
              _id: uuid
              uuid
              name
            }
          }
        }
      }`, { serialNumber },
    )

    return {
      loading,
      onResult,
      device: useResult(result, {}, data => data.device.get),
    }
  },
  getWithGroups () {
    const { result, loading } = useQuery(gql`
      query getDeviceWithGroups{
        device{
          all{
            serialNumber
            type
            sensors {
              type
            }
            group{
              uuid
              name
            }
          }
        }
      }`,
    )

    return {
      loading,
      devices: useResult(result, [], data => data.device.all),
    }
  },
  getWithSensors (serialNumber, from = null, to = null, pollInterval = 10 * 1000) {
    const { result, loading } = useQuery(gql`
      query getDeviceWithSensors($serialNumber: ID!, $sensorTypes: [SensorType!], $from: DateTime!, $to: DateTime) {
        device {
          get(serialNumber: $serialNumber) {
            _id: serialNumber
            serialNumber
            name
            type
            lastSeen
            createdAt
            description
            firmwareVersion
            batteryPercentage
            signalStrength
            group {
              _id: uuid,
              name
            }
            sensors(type: $sensorTypes) {
              id
              type
              measurements {
                recent {
                  type
                  value
                  datetime
                }
                window (from: $from, to: $to, interval: 24, unit: HOUR) {
                  type
                  min
                  max
                }
              }
            }
          }
        }
      }`, {
      serialNumber,
      sensorTypes: SensorTypeHelper.renderableTypes,
      from: from || moment.utc().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      to: to || moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    }, { pollInterval, enabled: AppStatus.inForeground })

    return {
      loading,
      device: useResult(result, {}, data => data.device.get),
    }
  },
  getSlabType (serialNumber) {
    const { result, loading, onResult } = useQuery(gql`
      query getDeviceSlabType($serialNumber: ID!) {
        device {
          get(serialNumber: $serialNumber) {
            _id: serialNumber
            group {
              _id: uuid
              slabType
            }
          }
        }
      }`, { serialNumber },
    )

    return {
      loading,
      onResult,
      slabType: useResult(result, {}, data => data.device.get.group.slabType),
    }
  },
  getAllWithLatestMeasurements (searchTerm = '', paused = false, pollInterval = 10 * 1000) {
    const { result, loading, refetch } = useQuery(gql`
      query getDevicesWithLatestMeasurements($sensorTypes: [SensorType!], $search: DeviceSearch) {
        device {
          all(search: $search) {
            _id: serialNumber
            serialNumber
            name
            type
            lastSeen
            createdAt
            group {
              _id: uuid
              uuid
              name
            }
            sensors(type: $sensorTypes) {
              _id: id
              type
              measurements {
                recent {
                  type
                  value
                }
              }
            }
          }
        }
      }`, {
      sensorTypes: SensorTypeHelper.renderableTypes,
      search: { terms: searchTerm },
    }, {
      pollInterval,
      fetchPolicy: 'cache-and-network',
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
    })

    return {
      loading,
      refetch,
      devices: useResult(result, [], data => data.device.all),
    }
  },
  getUnassigned (pollInterval = 10 * 1000) {
    const { result, loading } = useQuery(gql`
      query getUnassignedDevices($sensorTypes: [SensorType!]) {
        device {
          unassignedToGroup {
            _id: serialNumber
            name
            lastSeen
            createdAt
            serialNumber
            type
            batteryPercentage
            sensors(type: $sensorTypes) {
              id
              type
              measurements {
                recent {
                  type
                  value
                }
              }
            }
          }
        }
      }`, {
      sensorTypes: SensorTypeHelper.renderableTypes,
    }, {
      pollInterval,
      enabled: AppStatus.inForeground,
      fetchPolicy: 'cache-and-network',
    })

    return {
      loading,
      devices: useResult(result, [], data => data.device.unassignedToGroup),
    }
  },
  getMeasurementWindowForDevices (variableRef, all = false) {
    const { result, loading } = useQuery(gql`
      query getMeasurementWindowForDevices(
        $sensorTypes: [SensorType!]
        $search: DeviceSearch
        $interval: Int!
        $unit: IntervalUnit!
        $from: DateTime!
        $includeOlder: Boolean
        $to: DateTime
      ) {
        device {
          all(search: $search) {
            serialNumber
            type
            sensors(
              type: $sensorTypes
            ) {
              type
              measurements {
                window(
                  from: $from
                  to: $to
                  interval: $interval
                  unit: $unit
                  includeOlder: $includeOlder
                ) {
                  values
                  timestamps
                }
              }
            }
          }
        }
      }`, variableRef, {
      enabled: computed(() => variableRef.value.search.filter.length && !all),
      context: {
        timeout: 30000,
      },
    })
    return {
      loading,
      windows: useResult(result, [], data => data.device.all.reduce((windows, device) => {
        windows.push(device.sensors.map(sensor => ({
          ...sensor.measurements.window,
          type: sensor.type,
          device: { serialNumber: device.serialNumber },
        })))
        return windows
      }, [])),
    }
  },
}
