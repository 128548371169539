import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async prepare (exportData) {
    return await apolloClient.mutate({
      mutation: gql`mutation ($export: ExportInput!) {
        exportStart(export:$export)
      }`,
      variables: {
        export: exportData,
      },
      context: {
        timeout: -1,
      },
    })
  },
}
