import { Singleton } from '~gro-helpers'

class AvailableTypesHelper extends Singleton {
  constructor () {
    super(AvailableTypesHelper, instance => {
      instance._availableTypes = {}
    })
  }

  get amountStored () {
    return Object.keys(this._availableTypes).length
  }

  store (identifier, types = {}) {
    if (types.length === 0 || !identifier) return
    if (!this._availableTypes[identifier]) {
      this._availableTypes[identifier] = types
    } else {
      this._availableTypes[identifier] = {
        ...this.load(identifier),
        ...types,
      }
    }
  }

  load (identifier) {
    if (!identifier) return {}
    return this._availableTypes[identifier] || {}
  }
}

export default new AvailableTypesHelper()
