import fpe from 'node-fpe'
import _ from 'lodash'

function encryptor (domainString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789') {
  const password = process.env.VUE_APP_QR_ENCRYPTION_KEY
  if (!password) {
    throw new Error('No encryption key set in env')
  }

  return fpe({
    password,
    domain: domainString.split(''),
  })
}

export default {
  getType (code) {
    const parts = code.split(':')
    if (parts.length === 0) {
      return null
    }
    return parseInt(parts[0])
  },
  encodePairingCode (pairCode) {
    const payload = encryptor('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ').encrypt(pairCode)
    return `6:${payload}`
  },
  generatePairingCode (length = 64) {
    let pairingCode = ''
    while (pairingCode.length < length) {
      pairingCode += _.sample('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789')
    }
    return pairingCode
  },
  encodeInviteCode (serialNumber, region, isVirtual, uuid, password, ips) {
    uuid = this.convertUUIDtoQRString(uuid)
    const mainPayload = encryptor().encrypt(`${region}${isVirtual ? 1 : 0}${uuid}${password}${serialNumber}`)
    const hostPayload = encryptor().encrypt('US2')
    const ipPayload = ips.reduce((payload, ip) => {
      return `${payload}:${encryptor().encrypt(this.convertIPtoInt(ip).toString())}`
    }, '')
    return `9:${mainPayload}:${hostPayload}:${ipPayload}`
  },

  decode (code) {
    switch (this.getType(code)) {
      case 2:
        return this.decodeOutdatedRecoveryCodeForSerialNumber(code)
      case 6:
        return this.decodePaircode(code)
      case 9:
        return this.decodeInviteCode(code)
      case 10:
        return this.decodeOwnerRecovery(code)
    }

    throw new Error('Unknown type code')
  },

  // https://cthulhu.cell-0.com/grosens-cloud/project/-/wikis/Encrypted-codes-format
  decodeOutdatedRecoveryCodeForSerialNumber (payloadAndType) {
    const parts = payloadAndType.split(':')
    if (parts.length !== 2) {
      throw new Error('Code does not have the required number of parts')
    }
    const payload = encryptor('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ').decrypt(parts[1])

    if (payload.length !== 42) {
      throw new Error('Code does not have the right length')
    }

    return {
      serialNumber: payload.substr(0, 8),
      region: payload.substr(8, 2),
      recoveryKey: payload.substr(10, 32),
    }
  },

  // https://cthulhu.cell-0.com/grosens-cloud/project/-/wikis/Encrypted-codes-format
  decodePaircode (payloadAndType) {
    const parts = payloadAndType.split(':')
    if (parts.length !== 2) {
      throw new Error('Code does not have the required number of parts')
    }

    const password = process.env.VUE_APP_QR_ENCRYPTION_KEY
    if (!password) {
      throw new Error('No encryption key set in env')
    }

    const payload = encryptor('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ').decrypt(parts[1])

    if (payload.length !== 64) {
      throw new Error('Code does not have the right length')
    }

    return {
      pairCode: payload,
    }
  },

  // type 9
  // https://cthulhu.cell-0.com/grosens-cloud/project/-/wikis/Encrypted-codes-format
  decodeInviteCode (payloadAndType) {
    const parts = payloadAndType.split(':')
    if (parts.length < 3) {
      throw new Error('Code does not have the required number of parts')
    }

    const mainPayload = encryptor().decrypt(parts[1])
    const ips = parts.slice(3).map(payload => {
      if (!payload) return null
      const int = parseInt(encryptor().decrypt(payload))
      return this.convertIntToIp(int)
    })

    if (mainPayload.length <= 75) {
      throw new Error('Code does not have the right length')
    }

    return {
      region: mainPayload.substr(0, 2),
      isVirtual: !!parseInt(mainPayload.substr(2, 1)),
      uuid: this.convertQRStringToUUID(mainPayload.substr(3, 32)),
      password: mainPayload.substr(35, 40),
      serialNumber: mainPayload.substr(75),
      ips,
    }
  },

  // type 10
  // https://cthulhu.cell-0.com/grosens-cloud/project/-/wikis/Encrypted-codes-format
  decodeOwnerRecovery (payloadAndType) {
    const parts = payloadAndType.split(':')
    if (parts.length < 3) {
      throw new Error('Code does not have the required number of parts')
    }

    const mainPayload = encryptor().decrypt(parts[1])
    const ips = parts.slice(3).map(payload => {
      if (!payload) return null
      const int = parseInt(encryptor().decrypt(payload))
      return this.convertIntToIp(int)
    })

    if (mainPayload.length <= 67) {
      throw new Error('Code does not have the right length')
    }

    return {
      region: mainPayload.substr(0, 2),
      isVirtual: !!parseInt(mainPayload.substr(2, 1)),
      password: mainPayload.substr(3, 64),
      serialNumber: mainPayload.substr(67),
      ips,
    }
  },

  convertIPtoInt (ip) {
    return ip.split('.').reduce((number, part, index) => {
      number += (parseInt(part) % 256) * Math.pow(256, (3 - index))
      return number
    }, 0)
  },
  convertUUIDtoQRString (uuid) {
    return uuid.replace(/-/g, '').toUpperCase()
  },
  convertIntToIp (int) {
    int++
    return [3, 2, 1, 0].reduce((ip, index) => {
      const factor = Math.pow(256, index)
      const segment = Math.abs(Math.ceil(((int - factor)) / factor))
      int -= segment * factor
      ip = ip ? `${ip}.${segment}` : `${segment}`
      return ip
    }, '')
  },
  convertQRStringToUUID (string) {
    string = string.toLowerCase()
    return `${string.substr(0, 8)}-${string.substr(8, 4)}-${string.substr(12, 4)}-${string.substr(16, 4)}-${string.substr(20, 12)}`
  },
}
