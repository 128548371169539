import { apolloClient, Translator } from '~gro-plugins'
import { BridgeRepository } from '~gro-modules/Bridge'
import gql from 'graphql-tag'
import { useQuery, useResult } from '@vue/apollo-composable'
import get from 'get-value'
import { ROLE_OWNER } from './roles'

export default {
  getUsers () {
    const { result, loading, refetch } = useQuery(gql`
      query getUsers {
        auth {
          all {
            _id: uuid
            id
            uuid
            email
            role
            hasPassword
          }
        }
      }`)
    return {
      loading, refetch, users: useResult(result, [], data => data.auth.all.filter(user => user.role !== ROLE_OWNER)),
    }
  },
  getUser (uuid) {
    const { result, loading } = useQuery(gql`
      query getUser($uuid: ID!) {
        auth {
          getUser(uuid: $uuid) {
            _id: uuid
            uuid
            email
            role
            hasPassword
          }
        }
      }`, { uuid })
    return {
      loading, user: useResult(result, {}, data => data.auth.getUser),
    }
  },
  getSettings () {
    const { result, loading, onResult, refetch } = useQuery(gql`
      query getUserSettings {
        auth{
          settings{
            temperatureUnit
            language
          }
        }
      }`)

    return {
      refetch,
      onResult,
      loading,
      settings: useResult(result, {
        language: Translator.availableLanguages[0], temperatureUnit: null,
      }, data => {
        return {
          temperatureUnit: data.auth.settings.temperatureUnit, language: data.auth.settings.language,
        }
      }),
    }
  },
  async getSettingsAsync () {
    const result = await apolloClient.query({
      query: gql`query getUserSettingsAsync {
        auth{
          settings{
            temperatureUnit
            language
            axis{
              sensorType
              min
              max
              autoScale
            }
          }
        }
      }`,
      fetchPolicy: 'no-cache',
    })
    return get(result, 'data.auth.settings', { default: {} })
  },
  async getUserAsync (uuid) {
    const result = await apolloClient.query({
      query: gql`
        query getUserAsync($uuid: ID!) {
          auth {
            getUser(uuid: $uuid) {
              _id: uuid
              hasPassword
            }
          }
        }`,
      variables: { uuid },
      fetchPolicy: 'no-cache',
    })
    return get(result, 'data.auth.getUser', { default: null })
  },
  async getCurrentUser () {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) return -1

    let result = null
    try {
      result = await apolloClient.query({
        query: gql`
          query getCurrentUser{
            auth {
              currentUser {
                uuid
                role
                hasPassword
                email
              }
            }
          }`,
        fetchPolicy: 'no-cache',
      })
    } catch (error) {
      // ..
    }

    if (!result || result.data.networkStatus === 8) {
      if (bridge && bridge.failover()) {
        return this.getCurrentUser()
      }
    }
    return get(result, 'data.auth.currentUser', { default: null })
  },
  async checkTemperatureUnit () {
    const result = await apolloClient.query({
      query: gql`query checkTemperatureUnit{
        auth {
          settings {
            temperatureUnit
          },
        },
      }`,
      fetchPolicy: 'no-cache',
    })
    return !!get(result, 'data.auth.settings.temperatureUnit', { default: false })
  },
}
