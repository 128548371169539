import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async ping () {
    let result
    try {
      result = await apolloClient.mutate({
        mutation: gql`mutation { ping }`,
      })
    } catch (e) {
      console.error(e)
    }
    return !(!result || result.errors || result.data.ping !== 'pong')
  },
  async factoryReset () {
    return await apolloClient.mutate({
      mutation: gql`mutation {
        bridgeFactoryReset
      }`,
      context: {
        timeout: 30000,
      },
    })
  },

  async updateSettings (settings) {
    return await apolloClient.mutate({
      mutation: gql`mutation ($settings: BridgeSettingsInput!) {
        bridgeUpdateBridgeSettings(settings: $settings)
      }`,
      variables: {
        settings,
      },
    })
  },

  async updateToLatestVersion () {
    try {
      const result = await apolloClient.mutate({
        mutation: gql`mutation {
          bridgeUpdateToLatestVersion
        }`,
      })
      if (!result.errors) return true

      console.error(result.errors)
    } catch (e) {
      console.error(e)
    }
    return false
  },

  async reboot () {
    try {
      const result = await apolloClient.mutate({
        mutation: gql`mutation {
          bridgeReboot
        }`,
      })
      if (!result.errors) return true

      console.error(result.errors)
    } catch (e) {
      console.error(e)
    }
    return false
  },
}
