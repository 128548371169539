import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'
import { BridgeRepository } from '~gro-modules/Bridge'
import { authApi } from '~gro-modules/Auth'
import get from 'get-value'

export default {
  async invalidateUserPassword (uuid = null) {
    const useGeneratedUuid = !uuid
    if (useGeneratedUuid) {
      uuid = await authApi.generateOwnerUuid()
    }
    const result = await apolloClient.mutate({
      mutation: gql`mutation($uuid: ID!) {
        authInvalidateUserPassword(uuid: $uuid)
      }`,
      variables: {
        uuid: `${uuid}`,
      },
    })
    if (result.errors && useGeneratedUuid) {
      return this.invalidateUserPassword(process.env.VUE_APP_OWNER_USER_UUID)
    }
  },

  async updateSettings (input) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation update($input: SettingsInput!) {
        authUpdateSettings(settingsInput: $input)
      }`,
      variables: {
        input,
      },
    })
    if (result.errors) return result.errors

    const bridge = await BridgeRepository.getCurrent()
    if (bridge) await bridge.user.reloadSettings()
  },
  async invite (input) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation($input: InviteInput!){
        authInviteUser(invite: $input){
          token
          uuid
        }
      }`,
      variables: { input },
    })
    return {
      invite: get(result, 'data.authInviteUser', { default: {} }),
      errors: result.errors,
    }
  },

  async reinvite (uuid) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation($uuid: ID!){
        authReinviteUser(uuid: $uuid){
          token
          uuid
        }
      }`,
      variables: {
        uuid: `${uuid}`,
      },
    })
    return {
      invite: get(result, 'data.authReinviteUser', { default: {} }),
      errors: result.errors,
    }
  },

  async grantViewerAccess (pairCode, uuid) {
    return await apolloClient.mutate({
      mutation: gql`mutation ($pairCode: String!, $uuid: ID!) {
        authGrantViewerAccessToPairCode(pairCode: $pairCode, uuid: $uuid)
      }`,
      variables: {
        pairCode,
        uuid,
      },
    })
  },
  async updateUser (uuid, data = {}) {
    if (!uuid) {
      throw new Error('No uuid provided')
    }

    return apolloClient.mutate({
      mutation: gql`mutation($uuid: ID!, $input: UserInput!){
        authUpdateUser(uuid: $uuid, userInput: $input)
      }`,
      variables: {
        uuid: uuid,
        input: {
          email: `${data.email}`,
        },
      },
    })
  },

  async deleteUser (uuid) {
    if (!uuid) {
      throw new Error('No uuid provided')
    }

    return apolloClient.mutate({
      mutation: gql`mutation($uuid: ID!){
        authDestroyUser(uuid: $uuid)
      }`,
      variables: {
        uuid: uuid,
      },
    })
  },
}
