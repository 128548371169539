import axios from 'axios'
import BridgeRepository from './BridgeRepository'

export default {
  async claimBridge (region, growerType = null) {
    try {
      const bridge = await BridgeRepository.getCurrent()
      await axios.post(bridge.getHost().resolve('api/bridge/claim'), {
        grower_type: growerType,
        hardware_region: region,
      })
    } catch (e) {
      // ..
    }
  },

  async getBridgeVersionInfo () {
    try {
      const bridge = await BridgeRepository.getCurrent()
      const result = await axios.get(bridge.getHost().resolve('api/bridge/version-info'))
      return result.data
    } catch (e) {
      if (e?.response?.status === 404) {
        return { update_available: true }
      }
      console.error(e)
    }
    return null
  },

  async updateToLatestVersion () {
    try {
      const bridge = await BridgeRepository.getCurrent()
      await axios.post(`${process.env.VUE_APP_BRIDGE_UPDATE_URL}`, {
        serial_number: bridge.serialNumber,
      })
      return true
    } catch (e) {
      console.error(e)
    }
    return false
  },

  getCloudUrl (hardwareRegion, bridgeSerial = null) {
    let envKey = `VUE_APP_CLOUD_FALLBACK_URL_${hardwareRegion}`
    if (!process.env[envKey]) {
      console.warn(`Unable to match [${hardwareRegion}] to a cloud region. Using US as fallback`)
      envKey = 'VUE_APP_CLOUD_FALLBACK_URL_US'
    }
    if (bridgeSerial) {
      return `${process.env[envKey]}/${bridgeSerial}`
    }
    return process.env[envKey]
  },
}
